export function useLobbyGameApi() {
  const API_VER = useRuntimeConfig().API_VER_1
  const createEndpoint = (endpoint: string) => `${API_VER}/${endpoint}`
  return {
    JACKPOT: createEndpoint('slot/jackpot'),
    SUM_JACKPOT: createEndpoint('slot/sumjackpot'),
    LOBBY_CATEGORY: createEndpoint('brand/category'),
    LIST_LOBBY_GAME: createEndpoint('brand/games'),
    LIST_LOBBY_CASINO: createEndpoint('brand/livecasino'),
    LIVE_CASINO_URL: createEndpoint('casinoUrl'),
    GAME_URL: createEndpoint('gameUrl'),
    HOME_BANNER: createEndpoint('brand/banner'),
    LODE_CITY: createEndpoint('lode/cities'),
    LODE_RESULT: createEndpoint('lode/results'),
    LO_DE_GAMES: createEndpoint('game?partner_provider=vingame&display_type=8')
  }
}
